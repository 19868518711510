import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import Container from "../components/Container"
import panda_and_clock from "../images/panda_and_clock.svg"

const PlaygroundPage = () => (
  <Layout>
    <SEO title="Playground" />
    <Section style={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <H1>Test</H1>
        <p>hello {process.env.NODE_ENV}</p>
      </Container>
    </Section>
  </Layout>
)

export default PlaygroundPage
